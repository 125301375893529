.page-body-wrapper {
    min-height: calc(100vh - #{$navbar-height});
    background: $content-bg;
    padding-left: 0;
    padding-right: 0;
    &.full-page-wrapper {
        width: 100%;
        min-height: 100vh;
    }
}

.main-panel {
    min-height: calc(100vh - #{$navbar-height});
    margin-left: auto;
}

.content-wrapper {
    padding: 1.5rem 0;
    width: 100%;
    flex-grow: 1;
}